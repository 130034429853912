export const replaceItemAtIndex = <T extends any>(array: T[], index: number, ...item: T[]) => {
  array = array.slice();

  return [...array.slice(0, index), ...item, ...array.slice(index + 1)];
};

export const ensureArray = <T extends any>(value: T): T extends Array<unknown> ? T : T[] => {
  if (Array.isArray(value)) return value as any;
  return [value] as any;
};

export const batchArray = <T extends unknown>(array: T[], size: number): T[][] => {
  if (array.length <= size) return [array];

  return [array.slice(0, size), ...batchArray(array.slice(size), size)];
};
