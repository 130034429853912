import { useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Coin, NavigationButton, NavigationContentWithIcon, NavigationLink, NotificationIndicator, Skeleton } from '@binhatch/ui';
import { getUserName } from '@binhatch/utility';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  BellIcon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  Cog8ToothIcon,
  GiftIcon,
  SparklesIcon,
  StarIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { BeneficiaryKind, Feature, UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { ENVIRONMENT, VERSION } from '@/config';
import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { Cart } from '@/containers/useCart';
import { SideNavigation as NavigationState } from '@/containers/useSideNavigation';

import { useBalance } from '@/hooks/useBalance';

import { Configuration } from '@/containers/useConfiguration';
import { notificationApi } from '@/integrations/api';
import { Logo } from './Logo';

export const Navigation: React.FC = () => {
  const { context, logout } = Auth.useContainer();
  const [{ value: config }] = Configuration.useContainer();
  const { isOpen, toggle } = NavigationState.useContainer();
  const { count } = Cart.useContainer();
  const { balances } = useBalance();

  const notifications = useRemoteData({ key: 'useNotificationCount' }, () => notificationApi.listNotifications().then((r) => r.data.unreadCount));

  return (
    <header className="z-10 h-14 w-full flex-shrink-0 md:h-full md:w-72">
      <div className={classNames('bg-shade fixed left-0 top-0 z-10 w-full md:w-72', isOpen ? 'h-full' : 'h-14 md:h-full')}>
        <div className="relative flex items-center px-4 py-2 xl:px-8 xl:py-6">
          <Logo className="h-10 xl:h-14" />

          <button
            aria-label=""
            className={classNames(
              'absolute right-2 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded border border-gray-200 md:hidden'
            )}
            onClick={toggle}
          >
            {isOpen ? <XMarkIcon className="h-6 w-6 stroke-2" /> : <Bars3Icon className="h-6 w-6 stroke-2" />}
          </button>
        </div>

        <div
          className={classNames('absolute bottom-0 left-0 top-14 flex w-full flex-col overflow-y-auto xl:top-28', {
            'hidden md:flex': !isOpen
          })}
        >
          <ul className="flex-1">
            <li>
              <NavigationLink className="space-y-2" to={urls.profile.root}>
                <div className="flex items-center font-medium">
                  <UserCircleIcon className="mr-2 h-6 w-6" />

                  <Skeleton className="w-2/3" size="h-5" visible={!context?.user}>
                    {(!!context && getUserName(context.user)) || context?.user.details.email}
                  </Skeleton>
                </div>

                <div className="flex items-center font-medium">
                  <BriefcaseIcon className="mr-2 h-6 w-6" />

                  <Skeleton className="w-full" size="h-5" visible={!context?.client}>
                    {context?.client?.name}
                  </Skeleton>
                </div>

                {context?.user.role === UserRole.CustomerAdmin && (
                  <div className="flex items-center justify-between">
                    <FormattedMessage id={translations.enum.balance[BeneficiaryKind.Client]} />

                    <Coin>
                      <Skeleton className="w-10" size="h-5" visible={!balances}>
                        <FormattedNumber style="decimal" value={balances?.[BeneficiaryKind.Client]?.amount ?? 0} />
                      </Skeleton>
                    </Coin>
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <FormattedMessage id={translations.enum.balance[BeneficiaryKind.User]} />

                  <Coin>
                    <Skeleton className="w-10" size="h-5" visible={!balances}>
                      <FormattedNumber style="decimal" value={balances?.[BeneficiaryKind.User]?.amount ?? 0} />
                    </Skeleton>
                  </Coin>
                </div>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.promotions.root}>
                <NavigationContentWithIcon icon={StarIcon}>
                  <FormattedMessage id={translations.pages.promotionList.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            {config.features?.has(Feature.CustomDeals) && (
              <li>
                <NavigationLink to={urls.customDeals.root}>
                  <NavigationContentWithIcon icon={SparklesIcon}>
                    <FormattedMessage id={translations.pages.customDealList.title} />
                  </NavigationContentWithIcon>
                </NavigationLink>
              </li>
            )}

            <li>
              <NavigationLink to={urls.shop.root}>
                <NavigationContentWithIcon icon={BuildingStorefrontIcon}>
                  <div className="flex flex-1 items-center justify-between gap-4">
                    <FormattedMessage id={translations.pages.shop.title.customer} />

                    {count > 0 && <div className="rounded-full bg-white px-3">{count}</div>}
                  </div>
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.orders.root}>
                <NavigationContentWithIcon icon={GiftIcon}>
                  <FormattedMessage id={translations.pages.orderList.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            {context?.user.role === UserRole.CustomerAdmin && (
              <li>
                <NavigationLink to={urls.users.root}>
                  <NavigationContentWithIcon icon={UsersIcon}>
                    <FormattedMessage id={translations.pages.userList.title} />
                  </NavigationContentWithIcon>
                </NavigationLink>
              </li>
            )}

            <li>
              <NavigationLink to={urls.notifications}>
                <NavigationContentWithIcon icon={BellIcon}>
                  <FormattedMessage id={translations.pages.notificationList.title} />
                  {!!notifications.data && <NotificationIndicator className="ml-4" />}
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.settings.root}>
                <NavigationContentWithIcon icon={Cog8ToothIcon}>
                  <FormattedMessage id={translations.pages.settings.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationButton onClick={() => logout()}>
                <NavigationContentWithIcon icon={ArrowLeftOnRectangleIcon}>
                  <FormattedMessage id={translations.buttons.logout} />
                </NavigationContentWithIcon>
              </NavigationButton>
            </li>
          </ul>

          <div className="p-4 text-center text-sm">
            {ENVIRONMENT} - v{VERSION}
          </div>
        </div>
      </div>
    </header>
  );
};
